import React, { useState } from "react";
import "../Styles/FAQPage.css";
import Navbar from "./Navbar";

const FAQPage = () => {
  const [activeIndex, setActiveIndex] = useState(null);

  const faqs = [
    {
      question: "What is Aryadhenu?",
      answer: (
        <p>
          Aryadhenu is a brand that provides natural, chemical-free, and healthy sweeteners and wood-pressed mustard oil. We collaborate with farmers and agronomists to deliver high-quality goods such as Wood-Pressed Mustard Oil, A2 cow Ghee, Shudh Desi Khand, Shudh Desi Gud (Jaggery), Boora, Shakkar, and other pure ingredients.
        </p>
      ),
    },
    {
      question: "What products does Aryadhenu offer?",
      answer: (
        <>
        <p>Aryadhenu offers a wide range of natural products including:
        </p>
        <ul>
          <li>Aryadhenu Shudh Desi Khand (Substitute of Refined Sugar)</li>
          <li>Aryadhenu Shudh Desi Gud (Natural Jaggery)</li>
          <li>Aryadhenu Shakkar (Jaggery Powder)</li>
          <li>Aryadhenu Boora</li>
          <li>Aryadhenu Wood-Pressed Mustard Oil</li>
          <li>Aryadhenu A2 Cow Shudh Desi Ghee</li>
        </ul>
        </>
      ),
    },
    {
      question: "Are Aryadhenu products healthy?",
      answer: (
        <p>
          Aryadhenu products are not only healthy but also produced without any chemicals or preservatives. They are made using traditional methods to ensure purity and health benefits.
        </p>
      ),
    },
    {
      question: "Where are Aryadhenu products sourced from?",
      answer: (
        <p>
          Our products are sourced from local farmers who practice sustainable and traditional farming methods. We work closely with them to ensure that every product maintains its natural integrity.
        </p>
      ),
    },
    {
      question: "How do I purchase Aryadhenu products?",
      answer: (
        <p>
          You can easily purchase our products online through our official website, Amazon, Flipkart, Meesho, and other e-commerce platforms. Just browse the product categories, add items to your cart, and follow the checkout process to complete the purchase.
        </p>
      ),
    },
    {
      question: "Does Aryadhenu ship all over India and internationally?",
      answer: (
        <p>
          Currently, Aryadhenu ships across India. Please contact our customer service team for more details on shipping availability and charges for international orders.
        </p>
      ),
    },
    {
      question: "What payment options are available?",
      answer: (
        <>
        <p>Aryadhenu offers various payment options including:</p>
        
        
        <ul>
          <li>Credit/Debit Cards (Visa, MasterCard)</li>
          <li>Net Banking</li>
          <li>UPI</li>
          <li>Cash on Delivery (COD)</li>
        </ul>
        </>
      ),
    },
    {
      question: "How long does delivery take?",
      answer: (
        <p>
          Delivery times may vary depending on your location, but orders are typically processed and shipped within 2-5 business days. Once your order is shipped, you will receive a tracking number.
        </p>
      ),
    },
    {
      question: "How much is shipping?",
      answer: (
        <p>
          Once you click secure checkout, you can enter your delivery address and choose a shipping method. Shipping costs are then calculated and tracked onto your subtotal at the bottom of the page.
        </p>
      ),
    },
    {
      question: "Can I track my order?",
      answer: (
        <p>
          We rely on a global network of shipping partners and local postal services to get your order to your doorstep as soon as possible. For this reason, tracking may not always be available. If your order is being sent by a trackable service, these details will be included on your order and in the shipping email we send you.
        </p>
      ),
    },
    {
      question: "How can I register?",
      answer: (
        <p>
          You can register through the link <a href="https://aryadhenu.com/signup">https://aryadhenu.com/signup</a>. If you are already a customer of Aryadhenu, you can log in with your email and passwords that you have used during sign-up. If you forgot your password, you may request a new password with the e-mail address known to us.
        </p>
      ),
    },
    {
      question: "Need more help?",
      answer: (
        <p>
          If you require further assistance, please contact us by email at <a href="mailto:customercare@aryadhenu.com">customercare@aryadhenu.com</a> or Contact at: +91-9467843826.
        </p>
      ),
    },
    {
      question: "Why am I unable to place orders?",
      answer: (
        <>
        <p>This could be due to the following issues:</p>
        <ul>
          <li>Check if there is a payment decline. Please contact our customer care team to know more about payment issues.</li>
          <li>The item you have selected is out of stock.</li>
          <li>There is some technical or internet connectivity issue.</li>
          <li>Undeliverable to selected location/pin code.</li>
        </ul>
        </>
      ),
    },
    {
      question: "How does the product ordering process work?",
      answer: (
        <>
        <p>The process involves browsing products on our shop page, selecting products, adding to your cart, entering delivery information, selecting the address and payment method via Razor Pay and submitting payment.</p>
        <ol>
          <li>Sign up and create your account</li>
          <li>Login to your account</li>
          <li>Select the products you wish to purchase</li>
          <li>Provide shipping address</li>
          <li>Make payment</li>
          <li>You will receive acknowledgement and an order tracking number</li>
        </ol>
        </>
      ),
    },
    {
      question: "Can I track my delivery in real-time?",
      answer: (
        <p>
          Yes, customers can track their delivery in real-time using the platform’s tracking system.
        </p>
      ),
    },
    {
      question: "How do I know if delivery is available in my area?",
      answer: (
        <p>
          Delivery availability can be seen when entering your delivery information on the platform.
        </p>
      ),
    },
    {
      question: "Is my payment information secure?",
      answer: (
        <p>
          Yes, the platform implements secure payment processes to protect customers’ payment information.
        </p>
      ),
    },
    {
      question: "Can I use a coupon or promo code?",
      answer: (
        <p>
          Yes, customers can enter coupons or promo codes during the ordering process.
        </p>
      ),
    },
  ];

  const toggleFAQ = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  return (
    <div className="faq-container">
      <h1 className="faq-title">FAQ</h1>
      <div className="faq-list">
        {faqs.map((faq, index) => (
          <div key={index} className="faq-item">
            <button className="faq-question" onClick={() => toggleFAQ(index)}>
              <span>{faq.question}</span>
              <span>{activeIndex === index ? "-" : "+"}</span>
            </button>
            {activeIndex === index && <div className="faq-answer">{faq.answer}</div>}
          </div>
        ))}
      </div>
      <Navbar isHomePage={false}/>
    </div>
  );
};

export default FAQPage;
