import React, { useContext, useState } from 'react';
import { AppContext } from '../../AppContext';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import CreateBlog from './CreateBlog';
import '../../Styles/BlogList.css';
import Navbar from '../Navbar';
import AlertPopup from '../AlertPopup';
import '../../Styles/AdminBlogList.css'

const AdminBlogList = () => {
    const { blogs, setBlogs } = useContext(AppContext);
    const navigate = useNavigate();
    const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;
    const [alertMessage,setAlertMessage]=useState('')
    const [currentPage, setCurrentPage] = useState(1);
    const blogsPerPage = 4;
    const indexOfLastBlog = currentPage * blogsPerPage;
    const indexOfFirstBlog = indexOfLastBlog - blogsPerPage;
    const currentBlogs = blogs.slice(indexOfFirstBlog, indexOfLastBlog);
    const totalPages = Math.ceil(blogs.length / blogsPerPage);

    const [editingBlog, setEditingBlog] = useState(null);

    const handleNext = () => {
        if (currentPage < totalPages) setCurrentPage(currentPage + 1);
    };

    const handlePrevious = () => {
        if (currentPage > 1) setCurrentPage(currentPage - 1);
    };

    const handleEdit = (blog) => {
        setEditingBlog(blog); // Pass the selected blog for editing
    };

    const handleDelete = async (id) => {
        try {
            await axios.delete(`${BACKEND_URL}/delete-blog/${id}`);
            setBlogs((prevBlogs) => prevBlogs.filter((blog) => blog._id !== id));
            setAlertMessage('Blog deleted successfully!');
        } catch (error) {
            
            setAlertMessage('Error deleting blog. Please try again.');
        }
    };

    return (
        <div className='update-blog-component-container'>
            <AlertPopup message={alertMessage} onClose={() => setAlertMessage('')} />
            {editingBlog ? (
                // Render CreateBlog component when editingBlog is set
                <CreateBlog
                    blogToEdit={editingBlog}
                    onEditComplete={() => setEditingBlog(null)} // Reset editing state on cancel
                />
            ) : (
                <div className='-update-blog-list-container' style={{display:'flex',flexDirection:'column'}}>
                    <h2>Manage Blogs</h2>
                    <div className='update-blog-list'>
                        {currentBlogs.length ? (
                            currentBlogs.map((blog) => (
                                <div key={blog._id} className='admin-blog-item'>
                                    <h3>{blog.title}</h3>
                                    <p>{blog.summary}</p>
                                    <div className='blog-actions'>
                                        <button onClick={() => handleEdit(blog)} className='page-button'>Edit</button>
                                        <button onClick={() => handleDelete(blog._id)} className='page-button'>Delete</button>
                                    </div>
                                </div>
                            ))
                        ) : (
                            <p className='no-blog-msg'>No Blogs found.</p>
                        )}
                        <div className='pagination'>
                            <button
                                className='page-button'
                                onClick={handlePrevious}
                                disabled={currentPage === 1}
                            >
                                Previous
                            </button>
                            <span className='page-info'>
                                Page {currentPage} of {totalPages}
                            </span>
                            <button
                                className='page-button'
                                onClick={handleNext}
                                disabled={currentPage === totalPages}
                            >
                                Next
                            </button>
                        </div>
                    </div>
                </div>
            )}
            <Navbar isHomePage={false} />
        </div>
    );
};

export default AdminBlogList;
