import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import '../Styles/AdminDashboard.css';
import Navbar from './Navbar';
import AddProducts from './AddProducts';
import UpdateProduct from './UpdateProductForm';
import BlogForm from './Blogs/BlogForm';
import UpdateSlideForm from './UpdateSlideForm';
import CreateBlog from './Blogs/CreateBlog';
import AlertPopup from './AlertPopup';
import SaleForm from './SaleForm';
import ManageSales from './ManageSales';
import { AppContext } from '../AppContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faTimes } from '@fortawesome/free-solid-svg-icons';
import ProductImageForm from './ProductImageForm';
import AdminBlogList from './Blogs/AdminBlogList';
import FileUpload from './FileUpload';

const isTokenValid = () => {
    const token = localStorage.getItem('token');
    const expiry = localStorage.getItem('tokenExpiry');
    return token && new Date().getTime() < expiry;
};

function AdminDashboard() {
    const [orderDetails, setOrderDetails] = useState([]);
    const [filteredOrders, setFilteredOrders] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [sortDirection, setSortDirection] = useState('desc');
    const navigate = useNavigate();
    const [activeSection, setActiveSection] = useState('orderDetails');
    const [searchTerm, setSearchTerm] = useState('');
    const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;
    const [cancellationRequests, setCancellationRequests] = useState([]);
    const [showCancellationsOnly, setShowCancellationsOnly] = useState(false);
    const [slides, setSlides] = useState([]);
    const [selectedSlide, setSelectedSlide] = useState(null);
    const [alertMessage,setAlertMessage]=useState('')
    const [jobs,setJobs]=useState([]);
    const [selectedJob, setSelectedJob] = useState('');
    const {setNetWorkError}=useContext(AppContext)
    const [isFixedCostShippingEnabled,setIsFixedCostShippingEnabled]=useState(null)
    const [dateFilteredOrders,setDateFilteredOrders]=useState([])
    const [startDate,setStartDate]=useState("")
    const [endDate,setEndDate]=useState("")


    const [isMenuOpen,setIsMenuOpen]=useState(false)
    

    const toggleMenu = () => {
        setIsMenuOpen(!isMenuOpen);
        
    };

     const [job, setJob] = useState({
        title: '',
        company: '',
        location: '',
        description: ''
     });

    useEffect(() => {
        const email = localStorage.getItem('email');
        
        if (email !== 'aryadhenu@gmail.com') {
            setAlertMessage('Access denied. Only admin can view this page.');
            navigate('/login');
            return;
        }

        if (!isTokenValid()) {
            setAlertMessage('Session expired. Please log in again.');
            navigate('/login');
            return;
        }

        const fetchAllOrderDetails = async () => {
            setLoading(true);
            try {
                const response = await axios.post(`${BACKEND_URL}/get-all-orders`);
                const sortedOrders = response.data.sort((a, b) => new Date(b.orderDate) - new Date(a.orderDate));
                setOrderDetails(sortedOrders);
                setFilteredOrders(sortedOrders); // Initially, all orders are displayed
                setLoading(false);
            } catch (error) {
                
                setError('Failed to fetch order details.');
                setLoading(false);
            }
        };

        fetchAllOrderDetails();

        const tokenExpiryHandler = setInterval(() => {
            if (!isTokenValid()) {
                clearInterval(tokenExpiryHandler);
                setAlertMessage('Session expired. Redirecting to login.');
                navigate('/login');
            }
        }, 60000);

        return () => clearInterval(tokenExpiryHandler);
    }, [navigate]);

    useEffect(() => {
        const fetchCancellationRequests = async () => {
            try {
                const response = await axios.get(`${BACKEND_URL}/get-cancellation-request`);
                setCancellationRequests(response.data.map((request) => request));
            } catch (error) {
                
                setNetWorkError('Failed to fetch blogs. Please try again later.')
            }
        };
        fetchCancellationRequests();
    }, []);

    const getCancellationStatus = (orderId) => {
        const request = cancellationRequests.find(
            (request) => request.orderId === orderId
        );
        return request ? 'Cancellation Requested' : 'No Request';
    };

    const formatDateToIndian = (dateString) => {
        const date = new Date(dateString);
        return date.toLocaleDateString('en-IN', {
            day: '2-digit',
            month: '2-digit',
            year: 'numeric',
        });
    };

    // Handle Search Filter
    const handleSearch = (e) => {
        setSearchTerm(e.target.value);
    };

    // Apply filter based on search term
    // useEffect(() => {
    //     const filtered = orderDetails.filter((order) =>
    //         order.orderId.toLowerCase().includes(searchTerm.toLowerCase()) ||
    //         order.emailAddress.toLowerCase().includes(searchTerm.toLowerCase()) ||
    //         order.orderStatus.toLowerCase().includes(searchTerm.toLowerCase())
          
    //     );
    //     setFilteredOrders(filtered);
    // }, [searchTerm, orderDetails]);

     useEffect(() => {
        const filtered = orderDetails.filter((order) => {
            const matchesSearch = order.orderId.toLowerCase().includes(searchTerm.toLowerCase()) ||
                order.emailAddress.toLowerCase().includes(searchTerm.toLowerCase()) ||
                order.orderStatus.toLowerCase().includes(searchTerm.toLowerCase());

            const matchesCancellation = !showCancellationsOnly || getCancellationStatus(order.orderId) === 'Cancellation Requested';

            return matchesSearch && matchesCancellation;
        });
        setFilteredOrders(filtered);
    }, [searchTerm, orderDetails, showCancellationsOnly]);

    // Manual Sort (Sort by order date)
    // const sortByDate = () => {
    //     const sortedOrders = [...filteredOrders].sort((a, b) => new Date(a.orderDate) - new Date(b.orderDate));
    //     setFilteredOrders(sortedOrders);
    // };


     const toggleSortByDate = () => {
        const sorted = [...filteredOrders].sort((a, b) => {
            return sortDirection === 'desc'
                ? new Date(a.orderDate) - new Date(b.orderDate)
                : new Date(b.orderDate) - new Date(a.orderDate);
        });
        setFilteredOrders(sorted);
        setSortDirection(sortDirection === 'desc' ? 'asc' : 'desc'); // Toggle the sorting direction
    };

    // Pagination logic
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage] = useState(10);
    const totalPages = Math.ceil(filteredOrders.length / itemsPerPage);

    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    const currentOrders = filteredOrders.slice(
        (currentPage - 1) * itemsPerPage,
        currentPage * itemsPerPage
    );


    useEffect(() => {
    const fetchSlides = async () => {
      try {
        const response = await axios.get(`${BACKEND_URL}/slides/get-slides`);
        const slidesArray=Object.values(response.data)
        setSlides(slidesArray);
      } catch (error) {
        
        setNetWorkError('Failed to fetch blogs. Please try again later.')
      }
    };
    fetchSlides();
    }, []);

  const handleSlideSelect = (slide) => {
    setSelectedSlide(slide);
  };

  const handleSlideUpdate = (updatedSlide) => {
    setSlides((prevSlides) =>
      prevSlides.map((slide) =>
        slide._id === updatedSlide._id ? updatedSlide : slide
      )
    );
    setSelectedSlide(null); // Clear the selected slide after update
  };
   
  const handleChange = (e) => setJob({ ...job, [e.target.name]: e.target.value });

  const handleSubmit = async (e) => {
    e.preventDefault();
     const jobData = {
      ...job,
      description: job.description.split('\n').filter(paragraph => paragraph.trim() !== "")
    };
    
    try {
      await axios.post(`${BACKEND_URL}/post-job`, jobData);
      setAlertMessage('Job posted successfully!');
      setJob({
         title: '',
        company: '',
        location: '',
        description: ''
      })
    } catch (error) {
      
    }
  }; 

   useEffect(() => {
    const fetchJobs = async () => {
      try {
        const response = await axios.get(`${BACKEND_URL}/get-jobs`);
        setJobs(response.data);
      } catch (error) {
        
       
      }
    };
    fetchJobs();
  }, []);

  const handleDelete = async () => {
    if (!selectedJob) {
      setAlertMessage('Please select a job to delete.');
      return;
    }
    try {
      await axios.delete(`${BACKEND_URL}/delete-job/${selectedJob}`);
      setAlertMessage('Job deleted successfully');
      setJobs(jobs.filter((job) => job._id !== selectedJob));
      setSelectedJob('');
    } catch (error) {
      
      setAlertMessage('Failed to delete job');
    }
  };


   useEffect(() => {
        // Fetch current status
        const fetchStatus = async () => {
            try {
                const response = await axios.get(`${BACKEND_URL}/enable-shipping-cost`);
                setIsFixedCostShippingEnabled(response.data.isFixedCostShippingEnabled);
            } catch (error) {
                
            }
        };

        fetchStatus();
    }, []);
    const handleToggleFixedCost = async () => {
        try {
            const response = await axios.patch(`${BACKEND_URL}/update-shipping-cost-enable`, {
                isFixedCostShippingEnabled: !isFixedCostShippingEnabled,
            });

            
        

            setIsFixedCostShippingEnabled(!isFixedCostShippingEnabled);
            setAlertMessage(response.data.message);
        } catch (error) {
            
            setAlertMessage('Failed to update shipping option.');
        }
    };

    const filterByDate = () => {
    const filtered = orderDetails.filter((order) => {
        const orderDate = new Date(order.orderDate);
        const start = startDate ? new Date(startDate) : null;
        const end = endDate ? new Date(endDate) : null;

        return (!start || orderDate >= start) && (!end || orderDate <= end);
    });
    setDateFilteredOrders(filtered);
    if(filtered.length!==0){
         setAlertMessage('Orders filters. Proceed to download.')
    }else{
        setAlertMessage('Please choose dates to filter')
    }
    
};
  

//    const downloadFilteredOrders = () => {
//     if (!dateFilteredOrders.length) {
//         setAlertMessage("No orders to download.");
//         return;
//     }

//     // Prepare CSV content
//     const csvHeader = [
//         "Order ID",
//         "Status",
//         "Email",
//         "Phone Number",
//         "Order Date",
//         "Payment Status",
//         "Shipping Address",
//         "Transaction ID",
//         "Tracking Number",
//         "Total Amount",
//         "Products",
//         "Source",
//     ].join(",");

//     const csvRows = dateFilteredOrders.map((order) => {
//         // Combine product details into a single string
//         const products = order.products
//             ?.map((product) => `${product.productName} x ${product.quantity}`)
//             .join("; ") || "N/A";

//         const utmDetails =
//             order.utmDetails?.utm_source || order.utmDetails?.referrer || "N/A";

//         return [
//             order.orderId,
//             order.orderStatus,
//             order.emailAddress,
//             order.phoneNumber,
//             order.orderDate,
//             order.paymentStatus,
//             order.shippingAddress,
//             order.transactionId,
//             order.trackingNumber,
//             order.totalAmount,
//             products,
//             utmDetails,
//         ].join(",");
//     });

//     const csvContent = csvHeader + "\n" + csvRows.join("\n");

//     // Create a blob and download it
//     const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
//     const url = URL.createObjectURL(blob);
//     const link = document.createElement("a");
//     link.href = url;
//     link.download = `orders_${startDate || "all"}_${endDate || "all"}.csv`;
//     document.body.appendChild(link);
//     link.click();
//     document.body.removeChild(link);
// };

const downloadFilteredOrders = () => {
    if (!dateFilteredOrders.length) {
        setAlertMessage("No orders to download.");
        return;
    }

    // Prepare CSV content
    const csvHeader = [
        "Order ID",
        "Status",
        "Email",
        "Phone Number",
        "Order Date",
        "Payment Status",
        "Shipping Address",
        "Transaction ID",
        "Tracking Number",
        "Total Amount",
        "Discount Amount",
        "Coupon Code Applied",
        "Products",
        "Source",
    ]
        .map((header) => `"${header}"`)
        .join(",");

    const csvRows = dateFilteredOrders.map((order) => {
        // Combine product details into a single string
        const products = order.products
            ?.map((product) => `${product.productName} x ${product.quantity}`)
            .join("; ") || "N/A";

        const utmDetails =
            order.utmDetails?.utm_source || order.utmDetails?.referrer || "N/A";

        // Escape double quotes and wrap each field in double quotes
        const escapeField = (field) =>
            `"${String(field || "").replace(/"/g, '""')}"`;
        

        return [
            escapeField(order.orderId),
            escapeField(order.orderStatus),
            escapeField(order.emailAddress),
            escapeField(order.phoneNumber),
            escapeField(order.orderDate),
            escapeField(order.paymentStatus),
            escapeField(order.shippingAddress),
            escapeField(order.transactionId),
            escapeField(order.trackingNumber),
            escapeField(order.totalAmount),
            escapeField(order.discounts),
            escapeField(order.couponCode),
            escapeField(products),
            escapeField(utmDetails),
        ].join(",");
    });

    const csvContent = csvHeader + "\n" + csvRows.join("\n");

    // Create a blob and download it
    const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.download = `orders_${startDate || "all"}_${endDate || "all"}.csv`;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
};


  const onClose=()=>{
    setActiveSection('addBlogs')
  }



  
    

    return (
        <>
        { loading?<div className='loader-container'>
        <div className='loader'></div>
      </div>:(
        <div className="admin-dashboard">
            
            <aside className="sidebar">
                <button onClick={() => setActiveSection('orderDetails')}>Orders</button>
                <button onClick={() => setActiveSection('addProduct')}>Add Product</button>
                <button onClick={() => setActiveSection('updateProducts')}>Update Products</button>
                <button onClick={() => setActiveSection('addBlogs')}>Add Blog</button>
                <button onClick={()=>setActiveSection('updateBlogs')}>Update Blogs</button>
                {/* <button onClick={()=>setActiveSection('manageHeroSlides')}>Manage Hero Slides</button> */}
                <button onClick={()=>setActiveSection('addJob')}>Add Job</button>
                <button onClick={()=>setActiveSection('deleteJob')}>Delete Job</button>
                <button onClick={()=>setActiveSection('createSale')}>Create Sale</button>
                <button onClick={()=>setActiveSection('manageSale')}>Manage Sale</button>
                <button onClick={()=>setActiveSection('enableFixedCost')}>Fixed Shipping Charges</button>
                <button onClick={()=>setActiveSection('productImages')}>Create/Update Product Images</button>
                <button onClick={()=>setActiveSection('uploadImages')}>Upload Images</button>
            </aside>
            <div className='mobile-navbar-links'>
                <div className='mobile-menu-icon' onClick={toggleMenu}>
                    <FontAwesomeIcon icon={isMenuOpen ? faTimes : faBars}  style={{}}/>
                    </div>
                    
            </div>
             <div className={`mobile-nav-links ${isMenuOpen ? 'open' : ''}`}>
                <button onClick={() => {setActiveSection('orderDetails'); setIsMenuOpen(false)}}>Orders</button>
                <button onClick={() => {setActiveSection('addProduct'); setIsMenuOpen(false)}}>Add Product</button>
                <button onClick={() => {setActiveSection('updateProducts'); setIsMenuOpen(false)}}>Update Products</button>
                <button onClick={() => {setActiveSection('addBlogs'); setIsMenuOpen(false)}}>Add Blog</button>
                {/* <button onClick={()=>  {setActiveSection('manageHeroSlides'); setIsMenuOpen(false)}}>Manage Hero Slides</button> */}
                <button onClick={()=> {setActiveSection('addJob'); setIsMenuOpen(false)}}>Add Job</button>
                <button onClick={()=>{setActiveSection('deleteJob'); setIsMenuOpen(false)}}>Delete Job</button>
                <button onClick={()=>{setActiveSection('createSale'); setIsMenuOpen(false)}}>Create Sale</button>
                <button onClick={()=>{setActiveSection('manageSale'); setIsMenuOpen(false)}}>Manage Sale</button>
                <button onClick={()=>{setActiveSection('enableFixedCost');setIsMenuOpen(false)}}>Fixed Shipping Charges</button>
                       
                    </div>
            <AlertPopup message={alertMessage} onClose={() => setAlertMessage('')} />
            <main className="content">
                {activeSection === 'orderDetails' && (
                    <div className="table-container">
                        <h2>Orders</h2>
                        <div className='dashboard-filter-container'>
                        <input
                            type="text"
                            value={searchTerm}
                            onChange={handleSearch}
                            placeholder="Search by Order ID, Email or Status"
                            className="search-input"
                        />
                        <button onClick={toggleSortByDate}>
                        Sort by Date ({sortDirection === 'desc' ? 'Newest First' : 'Oldest First'})
                        </button>
                        <label>
                                <input
                                    type="checkbox"
                                    checked={showCancellationsOnly}
                                    onChange={(e) => setShowCancellationsOnly(e.target.checked)}
                                />
                                Show Cancellation Requests 
                            </label>
                        </div>
                        <table className="order-table">
                            <thead>
                                <tr>
                                    <th>Order ID</th>
                                    <th>Status</th>
                                    <th>Customer Name</th>
                                    <th>Email</th>
                                    <th>Phone Number</th>
                                    <th>Cancellation</th>
                                    <th>Payment Status</th>
                                    <th>Shipping Address</th>
                                    <th>Ordered Date</th>
                                    <th>Transaction ID</th>
                                    <th>Tracking Number</th>
                                    <th>Total Amount</th>
                                    <th>Discount</th>
                                    <th>Coupon Applied</th>
                                    <th>Ordered Products</th>
                                    <th>Source</th>
                                </tr>
                            </thead>
                            <tbody>
                                {currentOrders.map((order) => (
                                    <tr key={order.orderId}>
                                        <td>{order.orderId}</td>
                                        <td>{order.orderStatus}</td>
                                        <td>{order.customerName}</td>
                                        <td>{order.emailAddress}</td>
                                        <td>{order.phoneNumber}</td>
                                        <td>{getCancellationStatus(order.orderId)}</td>
                                        <td>{order.paymentStatus}</td>
                                        <td>{order.shippingAddress}</td>
                                        <td>{formatDateToIndian(order.orderDate)}</td>
                                        <td>{order.transactionId}</td>
                                        <td>{order.trackingNumber}</td>
                                        <td>{order.totalAmount}</td>
                                        <td>{order.discounts}</td>
                                        <td>{order.couponCode}</td>
                                        <td>
                                            <ul>
                                                {order.products?.map((product) => (
                                                    <li key={product.id}>{product.productName} X {product.quantity}</li>
                                                ))}
                                            </ul>
                                        </td>
                                        {order.utmDetails?
                                        <td>{order.utmDetails.utm_source || order.utmDetails.referrer}</td>
                                        :''}
                                    </tr>
                                ))}
                            </tbody>
                        </table>

                        <div className="dashboard-pagination">
                            <button
                                onClick={() => handlePageChange(currentPage - 1)}
                                disabled={currentPage === 1}
                                className='page-button'
                            >
                                Previous
                            </button>
                            <span>
                                Page {currentPage} of {totalPages}
                            </span>
                            <button
                                onClick={() => handlePageChange(currentPage + 1)}
                                disabled={currentPage === totalPages}
                                className='page-button'
                            >
                                Next
                            </button>
                        </div>

        <div className="date-filter-container">
            <label>
                Start Date:
                <input
                    type="date"
                    value={startDate }
                    onChange={(e) => setStartDate(e.target.value)}
                />
            </label>
            <label>
                End Date:
                <input
                    type="date"
                    value={endDate}
                    onChange={(e) =>setEndDate(e.target.value)}
                />
            </label>
            <button onClick={filterByDate}>Filter</button>
            <button onClick={downloadFilteredOrders}>Download Orders</button>
        </div>

                    </div>
                )}
                {activeSection === 'addProduct' && <AddProducts />}
                {activeSection === 'updateProducts' && <UpdateProduct />}
                {/* {activeSection === 'addBlogs' && <BlogForm/>} */}
                {activeSection === 'addBlogs' && <CreateBlog/>}
                {activeSection==='updateBlogs' && <AdminBlogList/>}
                {activeSection==='manageHeroSlides' &&
                <div className='manage-slides-container'>
                  <h2>Manage Slides</h2>
                  <div className='manage-slides-content'>
                   <ul>
                     {slides.map((slide) => (
                     <li key={slide._id}>
                     <h3>{slide.title}</h3>
                    <img src={slide.imgSrc} ></img>
                   <button onClick={() => handleSlideSelect(slide)}>Edit</button>
                   </li>
                   ))}
                 </ul>

                {selectedSlide && (
                <UpdateSlideForm slide={selectedSlide} onUpdate={handleSlideUpdate} />
                )}
                </div>
                </div>
                }
                {activeSection==='addJob' && 
                <div className='add-job'>
                 <form onSubmit={handleSubmit} >
                    <h2>Add a Job Post</h2>
                    <input type="text" name="title" placeholder="Job Title" value={job.title} onChange={handleChange} required />
                    <input type="text" name="company" placeholder="Company" value={job.company} onChange={handleChange} required />
                    <input type="text" name="location" placeholder="Location" value={job.location} onChange={handleChange} required />
                    <textarea name="description" placeholder="Description (Separate paragraphs with line breaks)" value={job.description} onChange={handleChange} required />
                    <button type="submit">Post Job</button>
                </form> 
                </div>
                }

                {activeSection==='deleteJob' &&
                  <div className='delete-job'>
                    <h2>Delete a Job</h2>
                    <select value={selectedJob} onChange={(e) => setSelectedJob(e.target.value)}>
                    <option value="">Select a job to delete</option>
                    {jobs.map((job) => (
                    <option key={job._id} value={job._id}>
                    {job.title}
                    </option>
                ))}
                </select>
                <button onClick={handleDelete} disabled={!selectedJob}>Delete Job</button>
                </div>
                }
                {activeSection==='createSale' && <SaleForm/>}
                {activeSection==='manageSale' && <ManageSales/>}
                {activeSection==='enableFixedCost' &&
                   <div className="admin-toggle">
                        <h2>Fixed-Cost Shipping Option</h2>
                        <label className="switch">
                        <input
                        type="checkbox"
                        checked={isFixedCostShippingEnabled}
                        onChange={handleToggleFixedCost}
                        />
                        <span className="shipping-slider"></span>
                        </label>
                        <p>{isFixedCostShippingEnabled ? 'Enabled' : 'Disabled'}</p>
                   </div>
                }
                {activeSection==='productImages' && <ProductImageForm/>}
                {activeSection=='uploadImages'  && <FileUpload onClose={onClose}/>}
            </main>
            
        </div>
           )}
        <Navbar isHomePage={false} />
        </>
    );
}

export default AdminDashboard;
