// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.update-blog-component-container {
    padding-top: 130px;
    min-height: 50vh;
    display: flex;
    
}

.update-blog-list-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-content: center;

}

.update-blog-list {
    width: 60%;
    margin-left: 20%;
}
.blog-actions{
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap:10px;
}`, "",{"version":3,"sources":["webpack://./src/Styles/AdminBlogList.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,gBAAgB;IAChB,aAAa;;AAEjB;;AAEA;IACI,aAAa;IACb,sBAAsB;IACtB,uBAAuB;IACvB,mBAAmB;IACnB,qBAAqB;;AAEzB;;AAEA;IACI,UAAU;IACV,gBAAgB;AACpB;AACA;IACI,aAAa;IACb,mBAAmB;IACnB,uBAAuB;IACvB,QAAQ;AACZ","sourcesContent":[".update-blog-component-container {\n    padding-top: 130px;\n    min-height: 50vh;\n    display: flex;\n    \n}\n\n.update-blog-list-container {\n    display: flex;\n    flex-direction: column;\n    justify-content: center;\n    align-items: center;\n    align-content: center;\n\n}\n\n.update-blog-list {\n    width: 60%;\n    margin-left: 20%;\n}\n.blog-actions{\n    display: flex;\n    flex-direction: row;\n    justify-content: center;\n    gap:10px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
