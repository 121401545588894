import React, { useContext,useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { AppContext } from '../AppContext';
import '../Styles/Logout.css';
import Navbar from './Navbar';

function Logout() {
    const { setLoginStatus } = useContext(AppContext);
    const navigate = useNavigate();

    useEffect(() => {
                   window.scrollTo(0, 0);
                }, []);

    const handleLogout = () => {
         localStorage.removeItem('token');
        localStorage.removeItem('tokenExpiry');
        localStorage.removeItem('email');
        localStorage.removeItem('username');
        localStorage.removeItem('user-picture');
        localStorage.removeItem('mobileNumber')
        setLoginStatus(false); // Update the login status in context
        navigate('/login'); // Redirect to the login page
    };



    const handleCancel=()=>{
        navigate(-1)
    }

    return (
        <>
        <div className='logout-card'>
            <h1>Are you sure you want to logout?</h1>
            <div className='btn-grp'>
            <button onClick={handleLogout}>Logout</button>
            <button onClick={handleCancel}>Cancel</button>
            </div>
             
        </div>
         <Navbar isHomePage={false}/>
        </>
    );
}

export default Logout;
