
import React, { useContext, useState,useEffect } from 'react';
import BlogItems from './BlogItems';
import { AppContext } from '../../AppContext';
import '../../Styles/BlogList.css';
import Navbar from '../Navbar';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHouse } from '@fortawesome/free-solid-svg-icons';
import axios from 'axios'


const BlogList = () => {
    const {
        filteredBlogs,
        setFilteredBlogs,
        searchTerm,
        setSearchTerm,
        selectedCategory,
        setSelectedCategory,
        categories,
        blogs,
        blogsLoading
        
    } = useContext(AppContext);

    const navigate = useNavigate();
    const [isLoading,setIsLoading]=useState(true)
    const BACKEND_URL=process.env.REACT_APP_BACKEND_URL

    // Utility function to strip HTML tags
const stripHtml = (html) => {
    const tempDiv = document.createElement('div');
    tempDiv.innerHTML = html;
    return tempDiv.textContent || tempDiv.innerText || '';
};

    
    // Pagination state
    const [currentPage, setCurrentPage] = useState(1);
    const blogsPerPage = 10; // Number of blogs to display per page


    // Filter blogs based on search term and selected category
    useEffect(() => {
        let updatedBlogs = blogs;

        // Filter by category
        if (selectedCategory) {
            updatedBlogs = updatedBlogs.filter(
                (blog) => blog.category === selectedCategory
            );
        }

        // Filter by search term
        if (searchTerm) {
            const lowercasedSearchTerm = searchTerm.toLowerCase();
            updatedBlogs = updatedBlogs.filter((blog) => {
                const strippedContent = stripHtml(blog.content).toLowerCase();
                return (
                    blog.title.toLowerCase().includes(lowercasedSearchTerm) ||
                    strippedContent.includes(lowercasedSearchTerm)
                );
            });
        }

        if (!searchTerm && !selectedCategory) {
            updatedBlogs = blogs;
        }

        setFilteredBlogs(updatedBlogs);
        setCurrentPage(1); // Reset to the first page after filtering
    }, [blogs, searchTerm, selectedCategory, setFilteredBlogs]);

    // Calculate pagination
    const indexOfLastBlog = currentPage * blogsPerPage;
    const indexOfFirstBlog = indexOfLastBlog - blogsPerPage;
    //const currentBlogs = blogs.slice(indexOfFirstBlog, indexOfLastBlog);
    const currentBlogs = filteredBlogs.slice(indexOfFirstBlog, indexOfLastBlog);

    const totalPages = Math.ceil(filteredBlogs.length / blogsPerPage);

    const handleNext = () => {
        if (currentPage < totalPages) {
            setCurrentPage(currentPage + 1);
        }
    };

    const handlePrevious = () => {
        if (currentPage > 1) {
            setCurrentPage(currentPage - 1);
        }
    };

    useEffect(() => {
        const timer = setTimeout(() => setIsLoading(false), 3000); 

        return () => clearTimeout(timer); 
    }, [navigate]);


    return (

        
        <div className='blog-component-container'>
            {blogsLoading?<div className='loader' ></div>:(
                <>
            <div className='page-info'>
                <div className='page-info-navigation'>
                    <div
                        className='page-info-navigation-icons'
                        onClick={() => {
                            navigate('/');
                        }}
                    >
                        <FontAwesomeIcon icon={faHouse}></FontAwesomeIcon>
                    </div>
                    <h5>Blogs</h5>
                </div>
            </div>
            <div className='blog-list-container'>
                <div className='filters-mobile'>
                    <input
                        type='text'
                        placeholder='Search blogs...'
                        onChange={(e) => setSearchTerm(e.target.value)}
                    />
                    <select
                        onChange={(e) => setSelectedCategory(e.target.value)}
                        value={selectedCategory}
                    >
                        <option value=''>All Categories</option>
                        {categories.map((category, index) => (
                            <option key={index} value={category}>
                                {category}
                            </option>
                        ))}
                    </select>
                </div>

                <div className='blog-list'>
                    {currentBlogs.length ? (
                        currentBlogs.map((blog) => (
                            <BlogItems key={blog._id} blog={blog}  searchTerm={searchTerm}/>
                        ))
                    ) : (
                        <p className='no-blog-msg'>No Blogs found.</p>
                    )}
                    <div className='pagination'>
                    <button
                        className='page-button'
                        onClick={handlePrevious}
                        disabled={currentPage === 1}
                    >
                        Previous
                    </button>
                    <span className='page-info'>
                        Page {currentPage} of {totalPages}
                    </span>
                    <button
                        className='page-button'
                        onClick={handleNext}
                        disabled={currentPage === totalPages}
                    >
                        Next
                    </button>
                </div>
                </div>
                
                <div className='filters'>
                    <input
                        type='text'
                        placeholder='Search blogs...'
                        onChange={(e) => setSearchTerm(e.target.value)}
                    />
                    <select
                        onChange={(e) => setSelectedCategory(e.target.value)}
                        value={selectedCategory}
                    >
                        <option value=''>All Categories</option>
                        {categories.map((category, index) => (
                            <option key={index} value={category}>
                                {category}
                            </option>
                        ))}
                    </select>
                </div>
            </div>
            </>
            )}
            <Navbar isHomePage={false} />
        </div>
        
    );
};

export default BlogList;

