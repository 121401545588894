import React, { useContext, useState,useEffect } from 'react'
import '../Styles/Login.css'
import { useNavigate,useLocation } from 'react-router-dom'
import axios from 'axios'
import Navbar from './Navbar'
import { AppContext } from '../AppContext'



function Login() {
    const [email,setEmail]=useState('')
    const [password,setPassword]=useState('')
    const [errors,setErrors]=useState({})
    const location=useLocation()
    const BACKEND_URL=process.env.REACT_APP_BACKEND_URL
    const {setLoginStatus}=useContext(AppContext)

    useEffect(() => {
       window.scrollTo(0, 0);
    }, []);
  
    const navigate=useNavigate()
    const handleLogin=async(event)=>{
        event.preventDefault();
        setErrors({}); 
        
     
    try {
      const response = await axios.post(`${BACKEND_URL}/login`, {
        email:email,
        password:password,
      })
      
      
     
      if (response.status === 200) {
        const { token,username,imageUrl,mobileNumber } = response.data;
        setLoginStatus(true) 
        

         const expiryTime = Date.now() + 30 * 60 * 1000; // 15 minutes in milliseconds
                localStorage.setItem('token', token);
                localStorage.setItem('tokenExpiry', expiryTime);
                localStorage.setItem('email', email);
                localStorage.setItem('username',username)
                localStorage.setItem('user-picture',imageUrl || '')
                localStorage.setItem('mobileNumber',mobileNumber)
                

                // navigate('/user-dashboard', { state: { email } }); 
                const adminEmail='aryadhenu@gmail.com'
                const redirect = email===adminEmail ?'/admin-dashboard':'/user-dashboard'

                const redirectPath = location.state?.from?.pathname || redirect
                navigate(redirectPath, { replace: true });
      
     
         
      } else {
        
        setErrors({ global: response.data.message });
      }
    } catch (error) {
      
      setErrors({ global: error.response?.data?.message  || "Something went wrong. Please try again later"});
    } 


    }
  return (
    <div className='login'>
        <div className='login-card'>
            <h1>Login to your Account</h1>
            <form className='login-form' onSubmit={handleLogin}>
                <input
                 type='text'
                 id='email'
                 required
                 placeholder='Email'
                 value={email}
                 onChange={(event)=>(setEmail(event.target.value))}
                 />
                 <input
                  type='password'
                  id='password'
                  required
                  placeholder='Password'
                  value={password}
                  onChange={(event)=>(setPassword(event.target.value))}
                  />
                  {errors.global && <div className='error-message'>{errors.global}</div>}
                  <button type='submit' className='login-page-button'>Login</button>
                  <div className='login-form-navigation'>
                  <a href='/request-password-rest'>Forgot Password</a>
                  <a href='/signup'>New User? Please create an Account</a>
                  </div>
            </form>
        </div>
        <Navbar isHomePage={false}/>
    </div>
  )
}

export default Login