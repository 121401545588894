


import React, { useState } from "react";
import { Editor } from "@tinymce/tinymce-react";

const CustomTinyMCEEditor = ({setContent,content,fileUpload}) => {
  // const [content, setContent] = useState("");

  const handleEditorChange = (newContent) => {
    console.log(newContent,'new content')
    setContent(newContent);
  };

  const tinymceKey=process.env.REACT_APP_TINY_MCE_KEY

  return (
    <Editor
      apiKey={tinymceKey}
      value={content}
      init={{
        height: 600,
        menubar: true,
        directionality: "ltr", // Set default text direction
        plugins: [
          "advlist", "autolink", "autosave", "code", "emoticons", "fullscreen", 
          "image", "imagetools", "link", "lists", "media", "preview", "quickbars", 
          "table", "wordcount",
        ],
        toolbar: `undo redo | styles | bold italic underline | fontselect fontsizeselect formatselect |
                  alignleft aligncenter alignright alignjustify | bullist numlist outdent indent |
                  link image media table | emoticons | preview fullscreen code `,
        font_formats: `Poppins=Poppins,sans-serif; Inter=Inter,sans-serif; Raleway=Raleway,sans-serif; Cursive=Cursive,cursive;`,
        content_style: `
          @import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600&family=Inter:wght@400;500;600&family=Raleway:wght@400;500;600&display=swap');
          body {
            font-family: Poppins, Inter, Raleway, Arial, sans-serif;
            font-size: 14pt;
            direction: ltr !important; /* Enforce LTR direction */
            text-align: left !important; /* Enforce left alignment */
          }
        `,
        forced_root_block_attrs: {
          dir: "ltr", // Force each block to have LTR direction
        },
        
        images_upload_handler: async (blobInfo, success, failure) => {
          try {
            if (fileUpload) {
              const file = blobInfo.blob();
              const url = await fileUpload(file, blobInfo.filename());
              success(url); // Pass the uploaded file URL to TinyMCE
            } else {
              failure("No upload handler provided.");
            }
          } catch (error) {
            console.error("Image upload failed:", error);
            failure("An error occurred during the upload.");
          }
        },
        
        
        
        
        
      }}
      onEditorChange={handleEditorChange}
    />
  );
};

export default CustomTinyMCEEditor;



