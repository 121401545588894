import React, { useState } from 'react';
import dummyImage from '../../Assets/user-dummy.png';
import '../../Styles/Reviews.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeftLong, faArrowRightLong } from '@fortawesome/free-solid-svg-icons';
import Testimonial1 from '../../Assets/Testimonials/Testimonial1.mp4';

const TestimonialsData = [
  {
    id: 1,
    testimonialVideo: Testimonial1,
    testimonialImage: '',
    author: 'Gaurav Aggarwal',
    role: 'Anitha Enterprises, Jaipur',
    content: '',
  },
  
];

function Testimonials() {
  const [page, setPage] = useState(1);
  const limit = 1; // Testimonials per page

  // Calculate paginated testimonials
  const indexOfLastTestimonial = page * limit;
  const indexOfFirstTestimonial = indexOfLastTestimonial - limit;
  const currentTestimonials = TestimonialsData.slice(
    indexOfFirstTestimonial,
    indexOfLastTestimonial
  );

  const totalPages = Math.ceil(TestimonialsData.length / limit);

  return (
    <div className='reviews'>
      <h1>Testimonials</h1>
      <div className='review-cards'>
        <ul>
        {currentTestimonials.map((testimonial) => (
          <div key={testimonial.id} className='review-card'>
            {testimonial.testimonialVideo ? (
              <video className='testimonial-media' controls>
                <source src={testimonial.testimonialVideo} type='video/mp4' />
                Your browser does not support the video tag.
              </video>
            ) : (
              <img
                src={testimonial.testimonialImage || dummyImage}
                alt={testimonial.author || 'User'}
                className='testimonial-media'
              />
            )}
            <h5>{testimonial.author}</h5>
            <p className='role'>{testimonial.role}</p>
            <p className='content'>{testimonial.content}</p>
          </div>
        ))}
        </ul>
      </div>
      <div className='scroll-cards'>
        <button
          className='left-scroll'
          onClick={() => setPage(page - 1)}
          disabled={page === 1}
        >
          <FontAwesomeIcon icon={faArrowLeftLong} />
        </button>
        <button
          className='right-scroll'
          onClick={() => setPage(page + 1)}
          disabled={page === totalPages}
        >
          <FontAwesomeIcon icon={faArrowRightLong} />
        </button>
      </div>
    </div>
  );
}

export default Testimonials;



