import React ,{useState,useEffect} from 'react'
import '../Styles/About.css'
import { useNavigate } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHouse, faGreaterThan} 
from '@fortawesome/free-solid-svg-icons'
import Navbar from './Navbar'
import about from '../Assets/AboutPage.jpeg'



function About() {

    const navigate=useNavigate()
    const [isLoading,setIsLoading]=useState(true)

    useEffect(() => {
       window.scrollTo(0, 0);
    }, []);

     useEffect(() => {
        const timer = setTimeout(() => setIsLoading(false), 1000); 

        return () => clearTimeout(timer); 
    }, [navigate]);
  return (
    <div className='about-container'>
        {isLoading?<div className='loader-container'>
        <div className='loader'></div>
      </div>:(<>
        <div className='page-info'>
            <h1>About</h1>
            <div className='page-info-navigation'>
                <div className='page-info-navigation-icons' onClick={()=>{navigate('/')}}><FontAwesomeIcon icon={faHouse}></FontAwesomeIcon></div>
                

            </div>
            <div className='about-content'>
                <img src={about} alt='about'/>
                <div className='about-content-text'>
                    <h3>About Us</h3>
                    <p>Aryadhenu stands as a beacon of excellence in producing natural, healthy, and chemical-free products. 
                        As an institution we are committed to preserving the legacy of traditional and natural products in India, 
                        Aryadhenu prides itself on delivering items that are not only pure but also free from artificial additives, 
                        sulfur and chemicals but also good for health.</p>
                    <p><b>Company's Vision- </b>Going forward, the company wants distributors in every city in India and at least 50 distributors overseas. Down the lane, In the next five years, the company is looking forward to a turnover of 100 Crore.</p>
                    <p><b>Company's Mission-</b> We look forward to changing our youth’s lifestyles with the help of our Shudh Desi Products and the experience of Shree. Acharya Varchaspati Ji in Spirituality, Art of Living, Vedas, and Philosophy. At present and in the future, the company aims to donate funds concerning seminars about the art of living and a healthy lifestyle.
                    </p>
                    <h3>Our Commitment to Natural Products</h3>

                    <p>At Aryadhenu, We emphasize on use of natural ingredients, ensuring that our products maintain their authentic taste and nutritional value. We believe in honouring India’s rich tradition of natural product usage, providing high-quality, wholesome options to consumers that align with our commitment to health and sustainability.
                    </p>
                    <h3>Collaboration with Farmers and Agronomists</h3>
                    <p>A key aspect of our approach is our collaboration with skilled farmers from families with deep-rooted agricultural expertise. These farmers, along with our dedicated agronomists, contribute their knowledge and experience to our production process. This synergy allows us to blend tradition with modern agricultural practices, resulting in innovative and rooted heritage products.
                    </p>
                    <h3>Industry Best Practices</h3>
                    <p>Consistency and quality are at the heart of Aryadhenu’s operations. We take pride in adhering to the best industry practices, which are reflected in every product we offer. Our dedication to excellence ensures that each item meets rigorous standards, providing our customers with dependable and superior products.</p>
                    <h3>Our Passion for Natural Sweeteners</h3>
                    <p>Our love for natural sweeteners drives us to create products that not only taste great but are also beneficial for your health. Whether it's our Shudh Desi Khand, Shudh Desi Gur,  Indian A2 Cow Ghee, Wood & Cold-Pressed Mustard Oil, or other natural offerings, Aryadhenu remains committed to providing options that support a healthier lifestyle. Aryadhenu continues to innovate and uphold its tradition of natural, high-quality products, making it a trusted choice for those who value authenticity and health.</p>
                    <h3> Founder: Shree Acharya Vachaspati Ji “The Visionary Founder of Aryadhenu”</h3>
                    <p>Our founder of Aryadhenu, Shree Acharya Varchaspati Ji was born in Village Balawas post Nalwa District Hissar Haryana and currently lives in Noida. He studied Gurukul (Sanskrit, Vedas, Philosophy, Spirituality and Aryurvedas).
                    </p>
                    <p>Later, after completing his studies in Gurukul he started his own Sanskrit Academy in his hometown Hissar (2003-2006). Then from 2006 to 2012 he conducted many seminars in PAN India and educated youths about Ayurveda, the art of living, and the Vedas. Then from 2012 to 2015, he traded in Desi Cow Ghee and Cow Milk in Pahadganj New Delhi and he made Gowshala at his residence in the village itself, so that purity was not compromised, from the cow milk the desi sweets were prepared to add desi Khand to it instead of Sugar and people responded extremely well and liked the taste of sweets made by Desi Khand. With prior experience and response from the market to Desi Khand, he founded in 2015 Aryadhenu for a healthy lifestyle.</p>
                    <p>Shree Acharya Vachaspati Ji is the visionary founder of Aryadhenu, a leading institution dedicated to producing natural, healthy, and chemical-free products. His profound understanding of traditional practices and his unwavering commitment to quality have been instrumental in shaping Aryadhenu into the esteemed brand it is today.</p>
                    <h3>Board of Director-Shmt. Arya Harshita Ji-</h3>
                    <p><b>Chief Executive Officer-Shree Dr Vijay Garg Sir-</b>PhD (BAE) MAE, B. Tech, Sr. Scientist Lead production (R&D).</p>


                </div>

            </div>
        </div>
         </>)}
        <Navbar isHomePage={false}/>
    </div>
  )
}

export default About