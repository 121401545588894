import React, { useContext ,useEffect,useState} from 'react';
import { AppContext } from '../../AppContext';
import { useParams } from 'react-router-dom';
import '../../Styles/BlogItem.css'
import Navbar from '../Navbar';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHouse, faGreaterThan} 
from '@fortawesome/free-solid-svg-icons'
import axios from 'axios'

const BlogItem = () => {
    const {blogs,networkError}=useContext(AppContext)
    const { id } = useParams();
    // const blog = blogs.find((b) => b._id === id);
    const [blog,setBlog]=useState(null)
    const [loading,setLoading]=useState(true)
    const navigate=useNavigate()
    const [error,setError]=useState(null)
    const BACKEND_URL=process.env.REACT_APP_BACKEND_URL
  
    useEffect(() => {
       window.scrollTo(0, 0);
    }, []);
    useEffect(() => {
        const fetchBlog = async () => {
            try {
                const foundBlog = blogs.find((b) => b._id === id);
                if (foundBlog) {
                    setBlog(foundBlog); // Use the blog from context if available
                } else {
                    // Fetch from the backend if not found in context
                    const response = await axios.get(`${BACKEND_URL}/blog/${id}`);
                    setBlog(response.data);
                }
            } catch (err) {
                setError('Blog not found or an error occurred while fetching it.');
            } finally {
                setLoading(false);
            }
        };

        fetchBlog();
    }, [id, blogs]);

    // useEffect(() => {
    //     // Simulate a delay to wait for data
    //     const timer = setTimeout(() => {
    //         setLoading(false); // Set loading to false after the delay
    //     }, 1000); // Adjust the time as needed (1000 ms = 1 second)

    //     return () => clearTimeout(timer); // Cleanup the timer on unmount
    // }, []);

    const navigateBack=()=>{
        navigate(-1)
    }
    
    if(networkError){
        return(
            <div className='error-class'>
                <p>{networkError}</p>
                <button onClick={() => window.location.reload()}>Retry</button>
            </div>
        )
    }
     
    

    
    return (
        <>
        <div className='blog-item-container'>
            <div className='page-info'>
            
            <div className='page-info-navigation'>
                <div className='page-info-navigation-icons' onClick={()=>{navigate('/')}}><FontAwesomeIcon icon={faHouse}></FontAwesomeIcon></div>
                
                <h5>Blogs</h5>
            </div>
            </div>
            
            
            {loading ? 
                <div className='loader-container'>
        <div className='loader'></div>
      </div>
            :(
            
            <div className='blog-item'>
            {networkError?<div className='error-message'>{networkError}</div>:(
             
             <>
             
            <h2>{blog.title}</h2>
            <p>by: {blog.author} on {new Date(blog.datePosted).toLocaleDateString()}</p>
            {/* <img src={blog.image} alt={blog.title} />
            {blog.content.map((section, index) => (
                <div key={index}>
                    <h3>{section.subheading}</h3>
                    {section.paragraphs.map((paragraph, pIndex) => (
                        <p key={pIndex}>{paragraph}</p>
                    ))}
                </div>
            ))} */}
            <div
               dangerouslySetInnerHTML={{ __html: blog.content }}
            ></div>

            {error && <div className='signup-erros'>{error}</div>}

            <button onClick={navigateBack} className='blog-details-back-btn'>Back</button>
            </>
            )}
            
            </div>
            )}
            
            

            
        </div>
        <Navbar isHomePage={false}/>
        </>
    );
};

export default BlogItem;
