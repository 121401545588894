import React, { useEffect } from 'react';

const ConversionTracker = ({  transactionId = '', value = '', currency = 'INR' }) => {
  useEffect(() => {
    if (window.gtag) {
      window.gtag('event', 'conversion', {
        send_to: "AW-11460015937/392dCIDsko0ZEMHux9gq",
        transaction_id: transactionId,
        value: value,
        currency: currency,
      });
    }
  }, [ transactionId, value, currency]);

  return null;
};

export default ConversionTracker;
